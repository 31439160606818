import { Outlet } from "react-router-dom";
import Footer from "components/partials/footer/Footer";
import { Header } from "components/partials/header/Header";

export const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
};
