import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { BarsIcon } from "assets/icons/BarsIcon";
import { CloseIcon } from "assets/icons/CloseIcon";
import LogoImage from "assets/images/logo-dark.webp";
import { useEffect } from "react";
import { MenuData } from "assets/data/MenuData";
import { NavLink } from "react-router-dom";

export const Header = () => {
    useEffect(() => {
        function windowScroll() {
            const header = document.getElementById("header");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                header?.classList.add("shadow-sm");
                header?.classList.add("bg-white");
            } else {
                header?.classList.remove("shadow-sm");
                header?.classList.remove("bg-white");
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0);
        return () => {
            window.removeEventListener("scroll", windowScroll);
        };
    }, []);

    return (
        <header id="header" className="fixed w-full z-999">
            <div className="container">
                <div className="w-full flex items-center gap-3">
                    <div className="w-40 lg:w-52">
                        <img
                            src={LogoImage}
                            className="w-full"
                            alt="Adroit Systems LLC FZ"
                        />
                    </div>
                    <div className="grow flex lg:justify-center lg:flex-row flex-row-reverse">
                        {/* Large Version Menu Bar */}
                        <div className="hidden lg:flex gap-10">
                            {MenuData.map((menu, index) => (
                                <Popover key={index}>
                                    {({ open }) => (
                                        <>
                                            <PopoverButton
                                                className={`text-lg hover:text-primary outline-none ${
                                                    open ? "text-primary" : ""
                                                }`}
                                            >
                                                {menu.title}
                                            </PopoverButton>
                                            <PopoverPanel
                                                transition
                                                anchor="bottom start"
                                                className="z-999 top-48 shadow-sm data-[closed]:opacity-0 rounded-lg absolute ease-in-out duration-500 p-3 bg-white border border-gray-100 flex flex-col gap-3"
                                            >
                                                {menu.subItems.map(
                                                    (item, index) => (
                                                        <NavLink
                                                            key={index}
                                                            to={item.url}
                                                            className="hover:text-primary"
                                                        >
                                                            {item.title}
                                                        </NavLink>
                                                    ),
                                                )}
                                            </PopoverPanel>
                                        </>
                                    )}
                                </Popover>
                            ))}
                        </div>

                        {/* Mobile Version Menu Drawer */}
                        <div className="lg:hidden">
                            <Popover>
                                {({ open }) => (
                                    <>
                                        <PopoverButton className="outline-none">
                                            {open ? (
                                                <CloseIcon className="size-10" />
                                            ) : (
                                                <BarsIcon className="size-10" />
                                            )}
                                        </PopoverButton>
                                        <PopoverPanel
                                            transition
                                            className="absolute shadow-sm bg-white w-screen left-0 top-16 py-5 px-3 lg:hidden duration-500 data-[closed]:opacity-0 ease-in-out"
                                        >
                                            {MenuData.map((menu, index) => (
                                                <div
                                                    key={index}
                                                    className="pl-5"
                                                >
                                                    <h6 className="text-lg font-semibold text-primary">
                                                        {menu.title}
                                                    </h6>
                                                    <div className="flex-col flex">
                                                        {menu.subItems.map(
                                                            (item, index) => (
                                                                <NavLink
                                                                    key={index}
                                                                    className="p-3 hover:text-primary"
                                                                    to={
                                                                        item.url
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </NavLink>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </PopoverPanel>
                                    </>
                                )}
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
