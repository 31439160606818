import AWSLogo from "assets/images/AWS.png";
import AzureLogo from "assets/images/Azure.png";
import GoogleLogo from "assets/images/Google_Cloud.png";
import MicrosoftLogo from "assets/images/Microsoft.png";
import VeritasLogo from "assets/images/Veritas.png";
import MS365Logo from "assets/images/MS365.png";
import GoogleWSLogo from "assets/images/GoogleWS.png";
import WebexLogo from "assets/images/WebEx.png";
import ZoomLogo from "assets/images/Zoom.png";
import AutomationAnywhereLogo from "assets/images/AutomationAnywhere.png";
import UiPathLogo from "assets/images/UiPath.png";

const OurExpertise = () => {
    return (
        <section className="polygon-bg">
            <div className="container text-center">
                <h2>Our Expertise</h2>
                <div className="expertise">
                    <h3>Cloud Solutions</h3>
                    <div>
                        <img src={AWSLogo} alt="Amazon Web Services" />
                        <img src={AzureLogo} alt="Microsoft Azure" />
                        <img src={GoogleLogo} alt="Google Cloud" />
                    </div>
                </div>
                <div className="expertise">
                    <h3>Infrastucture &amp; Migrations</h3>
                    <div>
                        <img src={MicrosoftLogo} alt="Microsoft" />
                        <img src={VeritasLogo} alt="Veritas" />
                    </div>
                </div>
                <div className="expertise">
                    <h3>Modern Workspace</h3>
                    <div>
                        <img src={MS365Logo} alt="Microsoft 365" />
                        <img src={GoogleWSLogo} alt="Google Workspace" />
                        <img src={WebexLogo} alt="WebEx" />
                        <img src={ZoomLogo} alt="Zoom" />
                    </div>
                </div>
                <div className="expertise">
                    <h3>Automation</h3>
                    <div>
                        <img
                            src={AutomationAnywhereLogo}
                            alt="Automation Anywhere"
                        />
                        <img src={UiPathLogo} alt="UiPath" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurExpertise;
