export const MenuData = [
	{
		title: "Who we are?",
		url: null,
		subItems: [
			{
				title: "Our Company",
				url: "/about-us",
			},
			{
				title: "Contact Us",
				url: "/contact-us",
			},
		],
	},
	{
		title: "Our Services",
		url: null,
		subItems: [
			{
				title: "Managed Services",
				url: "/services/manage-services",
			},
			{
				title: "Data Lifecycle",
				url: "/services/data-lifecycle",
			},
			{
				title: "Process Automtion",
				url: "/services/process-automation",
			},
			{
				title: "Mobile Device Management",
				url: "/services/mdm",
			},
		],
	},
	{
		title: "Our Expertise",
		url: null,
		subItems: [
			{
				title: "Cloud Migrations",
				url: "/expert/cloud-migration",
			},
			{
				title: "Modern Workspace",
				url: "/expert/modern-workspace",
			},
		],
	},
];
