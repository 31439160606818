import ContactUs from "components/sections/ContactUs";
import Hero from "components/sections/Hero";
import OurExpertise from "components/sections/OurExpertise";
import OurServices from "components/sections/OurServices";
import WhoWeAre from "components/sections/WhoWeAre";

export const Home = () => {
    return (
        <>
            <Hero />
            <OurServices />
            <WhoWeAre />
            <OurExpertise />
            <ContactUs />
        </>
    );
};
