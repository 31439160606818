import ManagedServicesImage from "assets/images/Data-Center.svg";
import DataLifeCycleImage from "assets/images/Data-Processing.svg";
import BotImage from "assets/images/Artificial-Intelligence.svg";
import MdmImage from "assets/images/Mdm.svg";

export const ServicesData = [
    {
        Image: ManagedServicesImage,
        title: "Managed Services",
        description:
            "We offer comprehensive IT support and proactive management to ensure your technology systems run smoothly, allowing you to focus on your core business.",
        url: "/",
    },
    {
        Image: DataLifeCycleImage,
        title: "Data Lifecycle",
        description:
            "Get secure and efficient handling of data from creation to disposal, ensuring data integrity, compliance, and optimal usage throughout its lifespan.",
        url: "/",
    },
    {
        Image: BotImage,
        title: "Process Automation",
        description:
            "Transform manual workflows into digital processes, enhancing efficiency, accuracy, and accessibility while driving innovation and business growth.",
        url: "/",
    },
    {
        Image: MdmImage,
        title: "Mobile Device Management",
        description:
            "Ensure the secure and efficient use of mobile devices within your organization, providing control over apps, data, and security settings to protect your business assets.",
        url: "/",
    },
];
