import HeroImage from "assets/images/Hero-1.svg";
import { NavLink } from "react-router-dom";

const Hero = () => {
    return (
        <>
            <section className="lg:h-dvh polygon-big-bg">
                <div className="container h-full">
                    <div className="md:flex h-full items-center">
                        <div className="lg:w-7/12 md:w-8/12 w-10/12 mx-auto mb-5 ">
                            <img src={HeroImage} alt="Adroit Systems Service" />
                        </div>
                        <div className="flex items-center w-10/12 mx-auto">
                            <div>
                                <h3 className="text-lg text-gray-400 uppercase mb-2">
                                    Our Motivation
                                </h3>
                                <h1 className="font-semibold lg:leading-normal text-4xl lg:text-5xl mb-5">
                                    Let's get{" "}
                                    <span className="text-primary">IT</span>{" "}
                                    done.
                                </h1>
                                <p className="text-lg max-w-xl mb-5">
                                    we strive to deliver excellence to our
                                    customers and colleagues. Our values are the
                                    cornerstone of all our actions and
                                    decisions.
                                </p>
                                <NavLink className="mt-6 btn" to="/">
                                    Get Started
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
