import { MenuData } from "assets/data/MenuData";
import Logo from "assets/images/logo-dark.webp";
import { NavLink } from "react-router-dom";
const Footer = () => {
    return (
        <footer className="footer-bg">
            <div className="container">
                <div className="flex flex-col">
                    <div className="lg:flex">
                        <div className="w-full lg:w-6/12 mb-5">
                            <div className="w-full">
                                <img src={Logo} alt="Adroit Systems LLC FZ" />
                                <p className="w-full mb-2">
                                    Let's get{" "}
                                    <span className="text-primary font-bold">
                                        IT
                                    </span>{" "}
                                    done.
                                </p>
                            </div>
                            <div>
                                <p className="text-balance">
                                    Adroit Systems LLC empowers global
                                    enterprises to operate their
                                    mission-critical systems and streamline
                                    operations while advancing IT modernization,
                                    optimizing data architectures, and ensuring
                                    security and scalability across public,
                                    private, and hybrid cloud environments.
                                    Leading companies and public sector
                                    organizations trust Adroit Systems LLC to
                                    deliver services that elevate performance,
                                    competitiveness, and customer experience
                                    across their IT landscapes.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:flex justify-evenly">
                            {MenuData.map((menu, index) => (
                                <div key={index} className="footer-menu">
                                    <h4>{menu.title}</h4>
                                    {menu.subItems.map((item, index) => (
                                        <div key={index}>
                                            <NavLink to={item.url}>
                                                {item.title}
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="copywrite">
                        &copy; {new Date().getFullYear()} Adroit Systems LLC FZ.
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
